

//  const IP =            'http://localhost:3002/api_www/';
//  const IP_SOCKET =     "http://localhost:3002"



 const IP = 'https://planer.artdruk.eu:3443/api_www/';
    const IP_SOCKET = "https://planer.artdruk.eu:3443"

export  {

   IP,IP_SOCKET

}
